.mains {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: transparent;
    flex-direction: column;
    padding: 20px;


    ul {
        display: grid;
        list-style: none;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 20px;
        width: 100%;
        max-width: 1200px;
        padding: 0;
        margin: 0;

       
        li {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 10px;
            border: 2px solid #ffdd26;
            padding: 20px;
            box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            background-color: #fff;
            transition: transform 0.3s ease;


            
            
            p {
                font-size: 16px;
                margin: 0;
            }
            
            .MuiSvgIcon-root {
                font-size: 40px;
                color: #fff;
                background-color: #ffdd26;
                border-radius: 50%;
                padding: 10px;
            }
            
            label {
                font-size: 18px;
                font-weight: bold;
            }
        }


    }
}




li:hover {
    transform: translateY(-5px);
}


/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    ul {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    li {
        padding: 15px;
    }

    .MuiSvgIcon-root {
        font-size: 30px;
        padding: 8px;
    }

    label {
        font-size: 16px;
    }

    p {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    ul {
        grid-template-columns: 1fr;
    }

    li {
        padding: 10px;
    }

    .MuiSvgIcon-root {
        font-size: 25px;
        padding: 6px;
    }

    label {
        font-size: 14px;
    }

    p {
        font-size: 12px;
    }
}
