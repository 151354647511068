.sec-container {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-image: url(../../images/img8.jpg);
    object-fit: cover;
    background-repeat: no-repeat;




    .hero-content {
        flex: 1;
        padding-top: 2vh;
        width: 250px;

        animation: fadeInUp 2s ease;


    }

    .hero-content h2 {
        font-size: 38px;
        margin: bottom 20px;

        color: #000000;


    }

    .hero-content p {
        font-size: 24px;
        line-height: 1.5;
        margin-bottom: 30px;
        padding-left: 3vh;
    }



    .hero-image {
        flex: 1;

        margin: auto;
        animation: fadeInRight 2s ease;
    }

    img {
        width: 98%;
        height: auto;
        border-radius: 10px;
    }
}

@media screen and (max-width: 768px) {
    .sec-container {
        flex-direction: column;
        /* Change flex-direction to column */
        /* Adjust margin */
        background-image: none;

        /* Adjust padding */
    }

    .hero-content {
        width: 1rem;
        /* Change width to 100% */
        /* Adjust margin */

    }

    .hero-image {
        padding-left: 1vh;
        margin: 20px 0;
        /* Adjust margin */
    }

    .hero-content h2 {
        font-size: 20rem;
        /* Adjust font-size */
    }

    .hero-content p {
        font-size: 18rem;
        /* Adjust font-size */
        margin-bottom: 20px;
    }



}


@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}