.dropdown-menu {
    position: absolute;
   
    left: 1;
   
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    display: none;
  }
  
  .navbar .list li:hover .dropdown-menu {
    display: block;
    background-color: transparent;
  }
  
  .dropdown-menu li {
    padding: 8px 16px;
  }
  
  .dropdown-menu li a {
    color: #fff;
    text-decoration: none;
  }
  
  .dropdown-menu li a:hover {
      color: #ffdd26;
  }
  