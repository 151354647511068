.portfolio{
    padding-left: 5%;
    height:100vh; 
    padding: 5%;
   background-position:bottom;
    background-image: url(../../images/img51.jpg);
    object-fit: cover;
    background-repeat: no-repeat;
   

    
}



.our{
    color: black;
}
.portfolio p{
    color: grey;
    text-align: justify;
}

.react-multiple-carousel__arrow--left{
    left: 43%;
    bottom: 5%;
}

.react-multiple-carousel__arrow--right {
    right: 43%;
    bottom: 5%;
}

.imgbox{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.one-box{
    overflow-y: hidden;
}

@media screen and (max-width: 700px) {
    .one-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    }
    .portfolio{
        height: 70vh;
    }

    .react-multiple-carousel__arrow--left{
       display: none;
    }
    
    .react-multiple-carousel__arrow--right {
    display: none;
    }


    .car{
        padding: 0;
    }

    h1{
        font-size: 20px;
    }
     p{
        font-size: 15px;
    }
    h3{
        font-size: 17px;
    }
    h4{
        font-size: 16px;
    }
    h5{
        font-size: 15px;
    }
    h6{
        font-size: 14px;
    }
}