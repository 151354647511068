.main{
    color: white;
    background: rgba(0,0,0,0.7) url('../../../public/Img/lab-furniture.jpg') no-repeat center ;
    background-size: cover;
    background-blend-mode: darken;
    height: 600px;
    width: 100%;
    padding-right: 5%;
}

.h-text{
  padding-top: 5%;
  padding-left: 10%;
  visibility: visible;
 
  
}
.h-text h1{
    font-weight: 600;
    font-size: 50px;
    line-height: 50px;
    color: rgba(255, 255, 255, 0.863);
    

}
.h-text p{
  max-width: 330px;
    text-align: justify;
    margin-right: 10px;
    color: rgba(255, 255, 255, 0.863);
}

.c-btn{
    max-width:330px;
    background-color :#ffdd26;
    color: #3a3a3a;
    padding: 10px;
    margin-right: 10px;
    border-radius: 5px;
    text-align: center;
    font-weight: 700;
}
.main a{
    text-decoration: none;
    color: grey;
}

.c-btn:hover {
    background-color: black;
    color: white;
}

@media screen and (max-width:700px) {
    .main{
        height: 400px;
    }
    .h-text h1{
        font-weight: 600;
        font-size: 25px;
        line-height: 27px;
    }
}


/* ....................animation.................. */


  


