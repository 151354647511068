.about-3{
    /* height: 500x; */
    /* padding: 60px; */
    background-color: #F9F9F9;
}

.about-3sub{

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    color: white;
    background: rgba(0,0,0,.7) url('../../images/genome-research-lab-tests.jpg') no-repeat center ;
    background-size: cover;
    background-blend-mode: darken;
    /* height: 300px; */
    /* border-radius: 10px; */
    flex-wrap: wrap;
    padding: 60px 10px;

}
.about-item-1, .about-item-2, .about-item-3, .about-item-4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-item-1 .about-icons{
    margin-bottom:20px ;
    padding: 10px;
    background-color: #ffb300;
    color: black;
    border-radius: 50%;
    height: 80px;
    width: 80px;
}
.about-item-2 .about-icons{
    margin-bottom:20px ;
    padding: 10px;
    background-color: #ffb300;
    color: black;
    border-radius: 50%;
    height: 80px;
    width: 80px;
}

.about-item-3 .about-icons{
    margin-bottom:20px ;
    padding: 10px;
    background-color: #ffb300;
    color: black;
    border-radius: 50%;
    height: 80px;
    width: 80px;
}
.about-item-4 .about-icons{
    margin-bottom:20px ;
    padding: 10px;
    background-color: #ffb300;
    color: black;
    border-radius: 50%;
    height: 80px;
    width: 80px;
}


@media (max-width:675px) {

    .about-3{
        padding: 20px;
    }
    

}
@media (max-width:450px) {

    .about-3sub{
        gap:50px
    }
    

}