h1,h2,h3,h4,h5,h6,p{
    margin: 0;
}
/* 
font-family: 'Playfair Display', serif;
font-family: 'Poppins', sans-serif;
font-family: 'Roboto', sans-serif; */
body{
    box-sizing: border-box;
}

.df{
    display: flex;
    justify-content: center;
    align-items: center;
}

