#cursor {
    width: 20px;
    height: 20px;
    background-color:#ffde26; /* Change this to your desired color */
    border-radius: 50%;
    position: absolute;
    pointer-events: none;
    z-index: 1000;
  }
  @media screen and (max-width:700px) {
    #cursor{
      width: 15px;
      height: 15px;

    }
    
}