


.Product-page {
  padding: 20px;

  h1{
    
    font-weight: bolder;
  }
  
  
  h2{
    font-weight: bolder;
    
  }
  h3 {
    color: yellow;

  }
  
}

.card {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  border: 1px solid yellow;
  border-radius: 8px;
  overflow: hidden;
  background-color: rgb(90, 93, 93);
  color: yellow;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.card:hover {
  transform: scale(1.05);
}

.card-content {
  padding: 20px;

 
}
.card-content p {
  color: #ffff;
}

.card-image img {
  padding: 1vh;
  margin-top: 10vh;
  width: 100%;
  height: auto;
  background-image: none;
  border-bottom: 1px solid yellow;
}




hr {
  border: 1px solid yellow;
}

@media (min-width: 600px) {
  .card {
    flex-direction: row;
  }

  .card-content, .card-image {
    flex: 1;
  }

  .card-image {
    max-width: 300px;
  }
}
