.service-1{
    
    background-image: linear-gradient(rgba(0, 0, 0, 0.658), rgba(0, 0, 0, 0.658)), url("../../../public/ProjectImg/image12.jpeg");
    height: 40vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;

     h1 {
       
        color: #ffdd26;
        font-weight: bold;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
        width: 80%;
        padding: 20px;
        text-align: center;
        font-size: 50px;

    }
    }